import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";


const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        meta: {
            requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
            title: '服务记录',
        },
        
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                
                "../views/Dashboard.vue")
            },{
                path: "/Aftershou_B2",
                name: "Aftershou_B2",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/Aftershou_B2.vue")
            },{
                path: "/Aftershou_B2",
                name: "Aftershou_B2",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/Aftershou_B2.vue")
            },{
                path: "/Fince",
                name: "Fince",
                meta: {
                    title: '客户管理'
                },
                component: () => import (
                "../views/Fince.vue")
            },{
                path: "/Order",
                name: "Order",
                meta: {
                    title: '订单管理'
                },
                component: () => import (
                "../views/Order.vue")
            },{
                path: "/Contract",
                name: "Contract",
                meta: {
                    title: '合同管理'
                },
                component: () => import (
                "../views/Contract.vue")
            },{
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (  
                '../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import ( 
                '../views/403.vue')
            }
        ],
    }, {
        path: "/Login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        "../views/Login.vue")
    }, {
        path: "/Workbench",
        name: "工作台",
        component: Home,
        children:[
            {
                path: "Family_A",
                name: "Family_A",
                meta: {
                    title: '家庭社区'
                },
                component: () => import (
                "../views/Workbench/Family_A.vue")
            },{
                path: "Family_B",
                name: "Family_B",
                meta: {
                    title: '家庭社区'
                },
                component: () => import (
                "../views/Workbench/Family_B.vue")
            },{
                path: "Family_C",
                name: "Family_C",
                meta: {
                    title: '家庭社区'
                },
                component: () => import (
                "../views/Workbench/Family_C.vue")
            },{
                path: "Fince_cw",
                name: "Fince_cw",
                meta: {
                    title: '财务管理'
                },
                component: () => import (
                "../views/Workbench/Fince_cw.vue")
            },{
                path: "Fince_cw_D",
                name: "Fince_cw_D",
                meta: {
                    title: '账单核对'
                },
                component: () => import (
                "../views/Workbench/Fince_cw_D.vue")

            },{
                path: "Fince_cw_B",
                name: "Fince_cw_B",
                meta: {
                    title: '账单核对'
                },
                component: () => import (
                "../views/Workbench/Fince_cw_B.vue")

            },{
                path: "Fince_cw_C",
                name: "Fince_cw_C",
                meta: {
                    title: '账单核对'
                },
                component: () => import (
                "../views/Workbench/Fince_cw_C.vue")

            },{
                path: "Manage",
                name: "Manage",
                meta: {
                    title: '客户管理'
                },
                component: () => import (
                "../views/Workbench/Manage.vue"),
            },{
                path: "Manage_2",
                name: "Manage_2",
                meta: {
                    title: '客户管理'
                },
                component: () => import (
                "../views/Workbench/Manage_2.vue"),
            },{
                path: "Manage_3",
                name: "Manage_3",
                meta: {
                    title: '客户管理3'
                },
                component: () => import (
                "../views/Workbench/Manage_3.vue"),
            },{
                path: "Manage_4",
                name: "Manage_4",
                meta: {
                    title: '客户管理3'
                },
                component: () => import (
                "../views/Workbench/Manage_4.vue"),
            },{
                path: "Report",
                name: "Report",
                meta: {
                    title: '销售报表'
                },
                component: () => import (
                "../views/Workbench/Report.vue")
            },{
                path: "Work",
                name: "Work",
                meta: {
                    title: '表单'
                },
                component: () => import (
                "../views/Workbench/Work.vue")
            },{
                path: "InfoM",
                name: "InfoM",
                meta: {
                    title: '信息采集'
                },
                component: () => import (
                "../views/Workbench/InfoM.vue")
            },{
                path: "Financial",
                name: "Financial",
                meta: {
                    title: '财务管理（新）'
                },
                component: () => import (
                "../views/Workbench/Financial.vue")
            },{
                path: "SalesManagement",
                name: "SalesManagement",
                meta: {
                    title: '销售管理（新）'
                },
                component: () => import (
                "../views/Workbench/SalesManagement.vue")
            },
            
            


            
        ]
    }, {
        path: "/Manage",
        name: "OA管理",
        component: Home,
        children:[
            {
                path: "Approve",
                name: "Approve",
                meta: {
                    title: '表单'
                },
                component: () => import (
                "../views/Manage/Approve.vue")
            },{
                path: "Attend",
                name: "Attend",
                meta: {
                    title: '表单'
                },
                component: () => import (
                "../views/Manage/Attend.vue")
            },{
                path: "Attend_2",
                name: "Attend_2",
                meta: {
                    title: '表单'
                },
                component: () => import (
                "../views/Manage/Attend_2.vue")
            },{
                path: "Attend_3",
                name: "Attend_3",
                meta: {
                    title: '表单'
                },
                component: () => import (
                "../views/Manage/Attend_3.vue")
            }, {
                path: "BaseTable",
                name: "basetable",
                meta: {
                    title: '通讯录'
                },
                component: () => import (
                "../views/Manage/BaseTable.vue")
            },{
                path: "BaseTable2",
                name: "basetable2",
                meta: {
                    title: '部门管理'
                },
                component: () => import (
                "../views/Manage/BaseTable2.vue")
            },{
                path: "BaseTable4",
                name: "basetable4",
                meta: {
                    title: '部门管理'
                },
                component: () => import (
                "../views/Manage/BaseTable4.vue")
            },{
                path: "Menu",
                name: "Menu",
                meta: {
                    title: '客户管理'
                },
                component: () => import (
                "../views/Manage/Menu.vue"),
            },{
                path: "PeopCent",
                name: "PeopCent",
                meta: {
                    title: '个人中心'
                },
                component: () => import (
                "../views/Manage/PeopCent.vue")
            },{
                path: "Role",
                name: "Role",
                meta: {
                    title: '客户管理'
                },
                component: () => import (
                "../views/Manage/Role.vue"),
            },{
                path: "Serve",
                name: "Serve",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/Manage/Serve.vue")
            },{
                path: "AttendanceGroup",
                name: "AttendanceGroup",
                meta: {
                    title: '考勤管理(新)'
                },
                component: () => import (
                "../views/Manage/AttendanceGroup.vue")
            },{
                path: "MailList",
                name: "MailList",
                meta: {
                    title: '通讯录(新)'
                },
                component: () => import (
                "../views/Manage/MailList.vue")
            },
            
            
        ]
    }, {
        path: "/AfterSales",
        name: "售后维护",
        component: Home,
        children:[
            {
                path: "Aftershou_A",
                name: "Aftershou_A",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/AfterSales/Aftershou_A.vue")
            },{
                path: "tabs",
                name: "tabs",
                meta: {
                    title: '考勤管理'
                },
                component: () => import (
                "../views/AfterSales/Tabs.vue")
            },{
                path: "Aftershou_C",
                name: "Aftershou_C",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/AfterSales/Aftershou_C.vue")
            },{
                path: "Aftershou_D",
                name: "Aftershou_D",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/AfterSales/Aftershou_D.vue")
            },{
                path: "Aftershou_E",
                name: "Aftershou_E",
                meta: {
                    title: '职称售后'
                },
                component: () => import (
                "../views/AfterSales/Aftershou_E.vue")
            },{
                path: "Business_A",
                name: "Business_A",
                meta: {
                    title: '工商财税'
                },
                component: () => import (
                "../views/AfterSales/Business_A.vue")
            },{
                path: "Business_B",
                name: "Business_B",
                meta: {
                    title: '知识产权'
                },
                component: () => import (
                "../views/AfterSales/Business_B.vue")
            },{
                path: "Business_B_b",
                name: "Business_B_b",
                meta: {
                    title: '知识产权'
                },
                component: () => import (
                "../views/AfterSales/Business_B_b.vue")
            },{
                path: "Eduta",
                name: "Eduta",
                meta: {
                    title: '成人学历'
                },
                component: () => import (
                "../views/AfterSales/Eduta.vue")
            },{
                path: "Business",
                name: "Business",
                meta: {
                    title: '工商财税（新）'
                },
                component: () => import (
                "../views/AfterSales/Business.vue")
            }
        ]
    }, {
        path: "/Business",
        name: "业务管理",
        component: Home,
        children:[
            {
                path: "Articles",
                name: "Articles",
                meta: {
                    title: '内部文章'
                },
                component: () => import (
                "../views/Business/Articles.vue")
            },{
                path: "Guides",
                name: "Guides",
                meta: {
                    title: '业务指南'
                },
                component: () => import (
                "../views/Business/Guides.vue")
            },{
                path: "Details",
                name: "Details",
                meta: {
                    title: '业务详情'
                },
                component: () => import (
                "../views/Business/Details.vue")
            },{
                path: "Busin_nav",
                name: "Busin_nav",
                meta: {
                    title: '业务导航'
                },
                component: () => import (
                "../views/Business/Busin_nav.vue")
            },
            
        ]
    }, {
        path: "/User",
        name: "会员管理",
        component: Home,
        children: [
            {
                path: "User",
                name: "User",
                meta: {
                    title: '用户列表'
                },
                component: () => import (
                    "../views/User/user.vue")
            },
            {
                path: "member",
                name: "member",
                meta: {
                    title: '会员订单'
                },
                component: () => import (
                    "../views/User/member.vue")
            },
            {
                path: "kefu",
                name: "kefu",
                meta: {
                    title: '专属客服'
                },
                component: () => import (
                    "../views/User/kefu.vue")
            },
            {
                path: "configure",
                name: "configure",
                meta: {
                    title: '会员配置'
                },
                component: () => import (
                    "../views/User/configure.vue")
            },
            {
                path: "Partner",
                name: "Partner",
                meta: {
                    title: '合伙人申请'
                },
                component: () => import (
                    "../views/User/Partner.vue")
            },
        ]
    },{
        path: "/Banner",
        name: "广告管理",
        component: Home,
        children: [
            {
                path: "banner",
                name: "banner",
                meta: {
                    title: '轮播图'
                },
                component: () => import (
                    "../views/Banner/banner.vue")
            },{
                path: "advert",
                name: "advert",
                meta: {
                    title: '广告位'
                },
                component: () => import (
                    "../views/Banner/advert.vue")
            },{
                path: "UserFeedback",
                name: "UserFeedback",
                meta: {
                    title: '用户反馈'
                },
                component: () => import (
                    "../views/Banner/UserFeedback.vue")
            }
            
        ]
    }, {
        path: "/Management",
        name: "商家管理",
        component: Home,
        children: [
            {
                path: "Ordinary",
                name: "Ordinary",
                meta: {
                    title: '普通商品'
                },
                component: () => import (
                    "../views/Management/Ordinary.vue")
            },{
                path: "Collage",
                name: "Collage",
                meta: {
                    title: '拼团商品'
                },
                component: () => import (
                    "../views/Management/Collage.vue")
            },{
                path: "Classify",
                name: "Classify",
                meta: {
                    title: '商品分类'
                },
                component: () => import (
                    "../views/Management/Classify.vue")
            },{
                path: "Seckill",
                name: "Seckill",
                meta: {
                    title: '秒杀商品'
                },
                component: () => import (
                    "../views/Management/Seckill.vue")
            },{
                path: "AfterSale",
                name: "AfterSale",
                meta: {
                    title: '售后保障'
                },
                component: () => import (
                    "../views/Management/AfterSale.vue")
            },{
                path: "Commenttag",
                name: "Commenttag",
                meta: {
                    title: '评论标签'
                },
                component: () => import (
                    "../views/Management/Commenttag.vue")
            },{
                path: "Commodity",
                name: "Commodity",
                meta: {
                    title: '商品标签'
                },
                component: () => import (
                    "../views/Management/Commodity.vue")
            },{
                path: "Evaluate",
                name: "Evaluate",
                meta: {
                    title: '用户评价'
                },
                component: () => import (
                    "../views/Management/Evaluate.vue")
            },
            
        ]
    }, {
        path: "/Configuration",
        name: "平台配置",
        component: Home,
        children: [
            {
                path: "Friendship",
                name: "Friendship",
                meta: {
                    title: '友情链接'
                },
                component: () => import (
                    "../views/Configuration/Friendship.vue")
            },
            {
                path: "NavigationCopy",
                name: "NavigationCopy",
                meta: {
                    title: '图标导航（移动）'
                },
                component: () => import (
                    "../views/Configuration/NavigationCopy.vue")
            },
            {
                path: "Cooperation",
                name: "Cooperation",
                meta: {
                    title: '合作案例'
                },
                component: () => import (
                    "../views/Configuration/Cooperation.vue")
            },{
                path: "Navigation",
                name: "Navigation",
                meta: {
                    title: '网站导航PC'
                },
                component: () => import (
                    "../views/Configuration/Navigation.vue")
            },{
                path: "SinglePageList",
                name: "SinglePageList",
                meta: {
                    title: '单页列表'
                },
                component: () => import (
                    "../views/Configuration/SinglePageList.vue")
            },{
                path: "SiteList",
                name: "SiteList",
                meta: {
                    title: '站点列表'
                },
                component: () => import (
                    "../views/Configuration/SiteList.vue")
            },{
                path: "ThirdPartySettings",
                name: "ThirdPartySettings",
                meta: {
                    title: '第三方配置'
                },
                component: () => import (
                    "../views/Configuration/ThirdPartySettings.vue")
            },{
                path: "SystemSettings",
                name: "SystemSettings",
                meta: {
                    title: '系统设置'
                },
                component: () => import (
                    "../views/Configuration/SystemSettings.vue")
            },{
                path: "OtherConfigurations",
                name: "OtherConfigurations",
                meta: {
                    title: '其他配置'
                },
                component: () => import (
                    "../views/Configuration/OtherConfigurations.vue")
            },
            
            
            
        ]
    },{
        path: "/Order",
        name: "订单管理",
        component: Home,
        children: [
            {
                path: "Coupon",
                name: "Coupon",
                meta: {
                    title: '优惠券管理'
                },
                component: () => import (
                    "../views/Order/Coupon.vue")
            },{
                path: "UserCoupons",
                name: "UserCoupons",
                meta: {
                    title: '用户优惠券'
                },
                component: () => import (
                    "../views/Order/UserCoupons.vue")
            },{
                path: "GroupOrder",
                name: "GroupOrder",
                meta: {
                    title: '拼团订单'
                },
                component: () => import (
                    "../views/Order/GroupOrder.vue")
            },{
                path: "PuzzleList",
                name: "PuzzleList",
                meta: {
                    title: '拼团列表'
                },
                component: () => import (
                    "../views/Order/PuzzleList.vue")
            },{
                path: "SecondKillOrder",
                name: "SecondKillOrder",
                meta: {
                    title: '秒杀订单'
                },
                component: () => import (
                    "../views/Order/SecondKillOrder.vue")
            },{
                path: "OrderRefund",
                name: "OrderRefund",
                meta: {
                    title: '退款'
                },
                component: () => import (
                    "../views/Order/OrderRefund.vue")
            },{
                path: "RegistrationOrder",
                name: "RegistrationOrder",
                meta: {
                    title: '报名订单'
                },
                component: () => import (
                    "../views/Order/RegistrationOrder.vue")
            },
            
            
        ]
    },

    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 路由守卫
router.beforeEach((to,from,next)=>{
    // to要跳转到的路径
    // from从哪个路径来
    // next往下执行的回调
    // 在localStorage中获取token
    let token=localStorage.getItem('accessToken')
    // 判断该页面是否需要登录
    // console.log(to)
       if(token) { // 判断是否已经登录过
             next()
           } else {
            if(to.fullPath && to.path ==='/login') { // 如果有此资源且是登录页
              next();
              console.log('登录页')
            } else if(!to.fullPath){ // 没有此资源，到404页面
              next()
              console.log('404页')
            } else { // 有此资源，没有登陆过，也不是登录页
              next({path: '/login'})
              console.log('4有此资源')
            }
          }

})


export default router;
