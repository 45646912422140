
import {createApp} from 'vue'
import App from './App.vue'
import Vue from 'vue';
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'


import './assets/css/icon.css'
import '@/assets/css/index.css'
import '@/assets/css/commodity.css'


import axios from 'axios'
// import echarts from 'echarts'
// Vue.prototype.$http = Axios;
// Vue.prototype.$echarts = echarts
// 路由判断登录 根据路由配置文件的参数
import AMap from 'vue-amap';

import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts 

axios.defaults.baseURL = 'https://rest-api.duxin365.com/';

axios.interceptors.request.use(function (config) {
    window.localStorage.getItem("accessToken")
    let token = window.localStorage.getItem("accessToken")
    if (token) {
        config.headers.Token = token;     
         return config;
    }
    return config
}, function (error) {
        // 针对特定的http状态码进行处理
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
    // 对响应数据做一些事情
    return response
}, error => {
    // 请求错误时做些事
    let status = ''
    if(error.request){
        status = error.request
    }else if(error.response){
        status = error.response
    }
    if (status) {
        switch (status.status) {
            case 400: error.message = '请求错误(400)'; 
                break;
            case 401: error.message = '未授权，请重新登录(401)';
                router.push('/Login')
                localStorage.setItem("accessToken", '');
                break;
            case 403: error.message = '拒绝访问(403)';

                break;
            case 404: error.message = '请求出错(404)'; 
                      router.push('/404')
                break;
            case 408: error.message = '请求超时(408)'; 
                break;
            case 500: error.message = '服务器错误(500)'; 
                      router.push('/500')
                break;
            case 501: error.message = '服务未实现(501)'; 
                break;
            case 502: error.message = '网络错误(502)'; 
                break;
            case 503: error.message = '服务不可用(503)'; 
                break;
            case 504: error.message = '网络超时(504)'; 
                break;
            case 505: error.message = 'HTTP版本不受支持(505)';
                break;
            default: error.message = `连接出错(${error.response.status})!`;
        }
    }else{
        error.message ='连接服务器失败!'
    }
    return Promise.reject(error)

})

const app = createApp(App)

installElementPlus(app)
app
    .use(store)
    .use(AMap)
    .use(echarts)
    .use(router)
    .mount('#app')