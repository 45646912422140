<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
                <div class="sidebar-logo-container">
            <a href="" class="sidebar-logo-link router-link-active">
                    <img :src="logo" class="sidebar-logo">
                    <h1 class="sidebar-title">{{ title }}</h1>
            </a>
            <transition name="sidebarLogoFade">
                <router-link key="collapse" class="sidebar-logo-link" to="/">

                </router-link>
            </transition>
        </div>
            <template v-for="item in items">
                <template v-if="item.childlist">
                    <el-submenu :index="item.path" :key="item.id">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.childlist">
                            <el-submenu
                                v-if="subItem.childlist"
                                :index="subItem.path"
                                :key="subItem.id"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.childlist"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.path"
                                :key="subItem.id"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.path" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import axios from 'axios'
// import bus from "../common/bus";
export default {
    data() {
        return {

            title: '豆腐管理系统',
            logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
            items: []
        };
    },
    computed: {
        onRoutes() {
            var that = this
            axios({
            method: 'post',
            url: '/admin/ajax/menuList',
            }).then(function (res) {
                if (res.code == 404 || res.code == 401) {
                    that.$router.push('/Login');
                } else if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
                console.log(res)
                } else {
                    that.items = res.data.data

                }
            })
            // return that.$route.path.replace("/", "");

            axios({
                    method: 'post',
                    url: '/admin/ajax/perssionList',
                }).then(function (res) {
                    if (res.code == 404 || res.code == 401) {
                    that.$router.push('/Login');
                } else {
                    localStorage.setItem('Pers', res.data.data.perssionList)
                }

            })
        },
        collapse(){
            return this.$store.state.collapse
        }
    }
};
</script>


<style scoped>
.sidebar-title {
    float: left;
    color: #fff;
    font-size: 20px;
}
.sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #2b2f3a;
    text-align: center;
    overflow: hidden;
}
.sidebar-logo {
    width: 28px;
    height: 28px;
    float: left;
    margin: 11px 13px 0 19px;
}
.sidebar-container a {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    height: 100%;
}
.sidebar {
    display: block;
    position: fixed;
    left: 0;
    top: 0px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.el-menu {
    overflow: hidden;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 210px;
    overflow: hidden;
    overflow-y: scroll;
}
.sidebar > ul {
    height: 100%;
}
ul::-webkit-scrollbar {
    display: none;
}
</style>
