<template>
    <div class="header" :class="collapse ? 'dsad' : ''">
        <div class="hehehe">
            <div class="header-right">
                <div class="collapse-btn" @click="collapseChage">
                    <img src="../assets/img/shouqi.png" v-if="!collapse" class="el-icon-s-fold imagss">
                    <img src="../assets/img/zhankai.png" v-else  class="el-icon-s-fold imagss">
                </div>
                <div class="header-user-con">
                    <i class="el-icon-bell" @click="Show(1)" style="margin-right:20px"></i>
                    <div style="display:flex"  @click="Show(2)">
                        <div class="user-avator">
                            <img :src="Data_staff.full_avatar">
                        </div>
                        <div class="Gli" style="margin-right:20px">{{Data_staff.realname}}</div>
                    </div>
                    <!-- 登录 -->
                    <ul v-show="isShow">
                        <li @click="denlu()" class="tuichu"><i class="el-icon-switch-button" style="margin-right: 7px;"></i>退出登录</li>
                    </ul>
                    <!-- 用户名下拉菜单 -->
                    <div class="Div_Top" v-if="Shows">
                        <div class="Div_T1">通知</div>
                        <div class="Div_ty" style="height: 270px;">
                            <el-scrollbar style="height:100%">
                                <div class="Div_T2">
                                    <img class="Div_T2_lef" src="" alt="">
                                    <span class="Div_T2_rigt">
                                        <span>你收到了新的周报</span>
                                        <span>3小时前</span>
                                    </span>
                                </div>
                                <div class="Div_T2">
                                    <img class="Div_T2_lef" src="" alt="">
                                    <span class="Div_T2_rigt">
                                        <span>你收到了新的周报</span>
                                        <span>3小时前</span>
                                    </span>
                                </div>
                                <div class="Div_T2">
                                    <img class="Div_T2_lef" src="" alt="">
                                    <span class="Div_T2_rigt">
                                        <span>你收到了新的周报</span>
                                        <span>3小时前</span>
                                    </span>
                                </div>
                                <div class="Div_T2">
                                    <img class="Div_T2_lef" src="" alt="">
                                    <span class="Div_T2_rigt">
                                        <span>你收到了新的周报</span>
                                        <span>3小时前</span>
                                    </span>
                                </div>
                            </el-scrollbar>    
                        </div>
                        <div class="Div_T3">
                            <span style="width:49.7%">清空通知</span>
                            <span class="Dii"></span>
                            <span style="width:49.7%">查看更多</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            Shows:false,
            fullscreen: false,
            isShow:false,
            name: "linxin",
            message: 2,
            title: '豆腐网',
            logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
            Data_staff:'',
        };
    },
    computed: {
        username() {
            let username = localStorage.getItem("ms_username");
            return username ? username : this.name;
        },
        collapse() {
            return this.$store.state.collapse;
        },
        
    },
    // mounted(){
    //     let token = window.localStorage.getItem("accessToken")
    // },
    mounted() {
        this.AAAAA()
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    },
    methods: {
        AAAAA() {
            var that = this
            axios.get('/admin/general/profile/index',{
                }).then(function (res) {
                if (res.data.code == 0) {
                    alert('请求失败');  
                } else{ 
                    that.Data_staff = res.data.data.staff
                }
            })  
        },
        Show(e) {
            var that = this
            if( e == 1 ){
                if (that.Shows == true) {
                    that.Shows = false
                } else {
                    that.Shows = true
                }
            }else if( e == 2 ){
                if (that.isShow == true) {
                    that.isShow = false
                } else {
                    that.isShow = true
                }
            }
        },
        denlu(){
            var that = this
            that.$confirm("确定要退出登录吗？", "提示", {
              type: "primary"
            }).then( _ => {
                localStorage.removeItem("accessToken");
                this.$router.replace("/Login");
            })
        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == "loginout") {
                localStorage.removeItem("ms_username");
                this.$router.push("/login");
            }
        },
        // 侧边栏折叠
        collapseChage() {
            if (this.collapse != true) {
                console.log('qwqweqwe')
            }
            this.$store.commit("hadndleCollapse", !this.collapse);
        }
    },
    
};
</script>
<style lang="scss" scoped>
ul {
    width: 130px;
    background: #fff;
    text-align: center;
    position: fixed;
    top: 60px;
    right: 25px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
ul li {
    height: 35px;
    line-height: 35px;
}
.header .hehehe {
    box-shadow: 0px 4px 8px 0px rgb(38 38 38 / 6%);
}
.header .Div_Top .Div_ty div:last-child{
    margin-bottom: 50px;
    border: none;
}
.header .Div_Top .Div_T2_lef {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    background: chocolate;
}
.header .Div_Top .Div_T2_rigt {
    float: left;
    margin-left: 15px;
}
.header .Div_Top .Div_T2_rigt span:first-child{
    font-size: 14px;
    color: #000;
    margin-bottom: 3px;
    display: block;
}
.header .Div_Top .Div_T2_rigt span:last-child{
    display: block;
    font-size: 13px;
    color: #8c8c8c;
}
.header .Div_Top .Div_T1 {
    text-align: center;
    color: #409EFF;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    border-bottom: 1px solid #E8E8E8;
}
.header .Div_Top .Div_T2 {
    overflow: hidden;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #E8E8E8;
}
.header .Div_Top .Div_T3 {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #E8E8E8;
    cursor: pointer;
    z-index: 999;
    background: #fff;
}
.header .Div_Top .Div_T3 span{
    float: left;
    font-size: 14px;
    color: #222;
    text-align: center;
}
.header .Div_Top .Dii {
    height: 50px;
    width: 1px;
    background: #E8E8E8;

}
.header .Div_Top {
    position: absolute;
    top: 55px;
    right: 10px;
    height: auto;
    min-height: 200px;
    max-height: 352px;
    width: 350px;
    background: #FFF;
    border: 1px solid #E4E7ED;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.header .el-icon-bell {
    margin-top: 15px;
    cursor: pointer;
}
.header .el-icon-bell:before {
    color: #000;
}
.Gli {
    float: right;
    font-size: 15px;
    color: #222;
    margin-top: 16px;
    margin-left: 10px;
}
.dsad {
    margin-left: 64px!important;
        animation-duration: 4s;
    animation-delay: 2s;
    -webkit-tap-highlight-color: transparent;
}
.F_ri2 {
    width: 100%!important;
}
.F_sild1 {
    width: 90%!important;
    margin-left: 64px;
}
.F_sild2 {
    transition:width 0.42s
}

.imagss {
    width: 14px;
    height: 14px;
}
.sidebar-logo-container {
  position: relative;
  width: 210px;
  float: left;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: 'PingFang SC', Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
.sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: 'PingFang SC', Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 22px;
    color: #fff;
    margin-left: 210px;
    position: relative;
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.06);
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 50px;

}
.header .logo {
    float: left;
    width: 250px;
    line-height: 50px;
}
.header-right {
    // float: right;
    // padding-right: 50px;
    background: #fff;
}
.header-user-con {
    display: flex;
    justify-items: center;
    height: 50px;
    position: fixed;
    right: 20px;
    z-index: 999;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    // float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 5px;
}
.el-dropdown-link {
    color: #222;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
.tuichu{
    color: #000;
    font-size: 15px;
}
</style>
